import { BannerAction, TourElement } from "core/types";
import {
  CheckCircleIcon,
  CircleSlashIcon,
  CloseIcon,
  ErrorIcon,
  InfoIcon,
} from "ds/icons";
import { Button } from "ds/ui";
import { useFocusElement } from "dsl/hooks/useFocusElement";
import { ElementType, useState } from "react";
import { tv, type VariantProps } from "tailwind-variants";
import { useTranslations } from "translations";

type BannerVariant = VariantProps<typeof bannerVariants>;
type BannerColor = Exclude<BannerVariant["color"], undefined>;

export type BannerProps = {
  actions?: BannerAction[];
  color: BannerColor;
  heading?: string;
  message: React.ReactNode | string;
  messageTour?: TourElement;
  noIcon?: boolean;
  onClose?: (e?: any) => void;
  testId?: string;
  withClose?: boolean;
};

const ICONS: Record<BannerColor, ElementType> = {
  success: CheckCircleIcon,
  danger: CircleSlashIcon,
  warning: ErrorIcon,
  primary: InfoIcon,
};

const bannerVariants = tv({
  slots: {
    wrapper:
      "flex flex-row rounded-lg border-1 border-solid border-gray-500 p-4 space-between w-full items-center gap-3",
    icon: "h-5 w-5",
    bannerActions: "ml-3 flex items-center gap-6 overflow-visible",
    bannerContent: "flex flex-col flex-1 gap-2",
    bannerheading: "font-semibold",
    bannerMessage: "text-body-md",
  },
  variants: {
    color: {
      success: {
        wrapper: "bg-success-light",
        icon: "text-success-dark",
      },
      danger: { wrapper: "bg-danger-light", icon: "text-danger-dark" },
      primary: {
        wrapper: "bg-white",
        icon: "text-primary",
      },
      warning: {
        wrapper: "bg-warning-light",
        icon: "text-warning-dark",
      },
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

const BannerCloseIcon = ({
  actions,
  color,
  onClose,
  setOpen,
  withClose,
}: Pick<BannerProps, "onClose" | "withClose" | "actions" | "color"> & {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const firstFocusableElement = useFocusElement<HTMLButtonElement>({
    dependencies: [withClose],
    shouldFocus: !!withClose && !actions?.length,
  });
  const translations = useTranslations();

  if (!withClose && !onClose) return null;

  return (
    <Button
      id="close"
      isIconOnly
      aria-label={translations.actions.close}
      // data-testid was "notification-close" before (careful e2es!)
      data-testid="banner-close"
      onClick={(e) => {
        setOpen(false);
        onClose?.(e);
      }}
      ref={firstFocusableElement}
      size="xs"
      color={color}
      variant="light"
    >
      <CloseIcon />
    </Button>
  );
};

const BannerAdditionalActions = ({
  actions,
  color,
  withClose,
}: Pick<BannerProps, "withClose" | "actions" | "color">) => {
  const firstFocusableElement = useFocusElement<HTMLButtonElement>({
    dependencies: [withClose],
    shouldFocus: !!actions,
  });

  if (!actions?.length) return null;

  return (
    <>
      {actions.map(({ dataTestId, label, onClick, tour }, index) => (
        <span key={label} {...tour}>
          <Button
            id={label}
            data-testid={dataTestId}
            onClick={onClick}
            ref={index === 0 ? firstFocusableElement : undefined}
            variant="solid"
            color={color}
            size="sm"
          >
            {label}
          </Button>
        </span>
      ))}
    </>
  );
};

const BannerActions = ({
  actions,
  color,
  onClose,
  setOpen,
  withClose,
}: Pick<BannerProps, "actions" | "onClose" | "withClose" | "color"> & {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { bannerActions } = bannerVariants();
  if (!actions?.length && !withClose && !onClose) return null;

  return (
    <div className={bannerActions()}>
      <BannerAdditionalActions actions={actions} color={color} />
      <BannerCloseIcon
        actions={actions}
        onClose={onClose}
        setOpen={setOpen}
        withClose={withClose}
        color={color}
      />
    </div>
  );
};

const BannerIcon = ({
  color,
  noIcon,
}: Pick<BannerProps, "noIcon" | "color">) => {
  const { icon } = bannerVariants({
    color,
  });
  const Icon = ICONS[color];

  if (noIcon) return null;

  return <Icon className={icon()} />;
};

const BannerContent = ({
  heading,
  message,
  messageTour,
}: Pick<BannerProps, "heading" | "message" | "messageTour">) => {
  const Container = typeof message === "string" ? "p" : "div";
  const { bannerContent, bannerheading, bannerMessage } = bannerVariants();

  return (
    <div className={bannerContent()}>
      {heading && <p className={bannerheading()}>{heading}</p>}
      <Container className={bannerMessage()} {...messageTour}>
        {message}
      </Container>
    </div>
  );
};

export const Banner = ({
  actions,
  color = "primary",
  heading,
  message,
  messageTour,
  noIcon,
  onClose,
  testId,
  withClose,
}: BannerProps) => {
  const [open, setOpen] = useState(true);
  const { wrapper } = bannerVariants({
    color,
  });

  if (!open) return null;

  return (
    <div className={wrapper()} data-testid={testId}>
      <BannerIcon color={color} noIcon={noIcon} />
      <BannerContent
        heading={heading}
        message={message}
        messageTour={messageTour}
      />
      <BannerActions
        actions={actions}
        setOpen={setOpen}
        withClose={withClose}
        onClose={onClose}
        color={color}
      />
    </div>
  );
};

Banner.displayName = "RecareUI.Banner";
